

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
*.disabled{
  opacity: 0.7;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact !important;
  font-family: Calibri, 'Trebuchet MS', sans-serif;

}

code {
  font-family:  Calibri, sans-serif,source-code-pro, Menlo, Monaco, Consolas, Courier New,
    monospace;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    padding: 0;
    margin: 0;
}
.text-green {
  color: #6cff3c !important;
}

.contactform input.form_submit {
  background-color: #37B6FF;
  color: #000;
  width: 40%;
  padding: 12px !important;
}

.img-right {
  right: 15px;
  position: absolute;
  width: 31px !important;
  padding-top: 6px;
}

ul.features_list_detailed li .feat_small_details h4,
.feat_small_details {
  color: #fff;
}

ul.features_list_detailed li .feat_small_icon {
  padding-top: 5px;
}

.success {
  padding: 10px;
  background-color: #6cff3c;
  color: #000;
  font-size: 14px;
  font-weight: 700;
}

.jconfirm .jconfirm-box div.jconfirm-title-c .jconfirm-title {
  color: #000 !important;
  font-weight: 600;
}

.jconfirm .jconfirm-holder {
  max-height: 100%;
  padding: 50px 10px;
}

.jconfirm-content div {
  color: #000 !important;
  font-weight: 500;
}


.page-content{
  background-color: white;
}
.homepage_button {
  width: 100%;
  margin: 10px 0 0 0;
  text-align: center;
  cursor: pointer;
  /* color: #fff; */
  /* background-color: #ff6251; */
  padding: 12px 30px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-appearance: none;
  background-color: #37B6FF;
  color: #000;
}
#pages_maincontent{
  background-color: white;
}

.page_title{
  background-color: black !important;
  color: white !important;
  margin: 0 !important;
  padding: 20px 10px !important;
  width: 100% !important; 
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative;
}

.text-green {
  color: #6cff3c !important;
}


.img-right {
  right: 15px;
  position: absolute;
  width: 31px !important;
  padding-top: 6px;
}

ul.features_list_detailed li .feat_small_details h4,
.feat_small_details {
  color: #fff;
}

ul.features_list_detailed li .feat_small_icon {
  padding-top: 5px;
}

.success {
  padding: 10px;
  background-color: #6cff3c;
  color: #000;
  font-size: 14px;
  font-weight: 700;
}

.jconfirm .jconfirm-box div.jconfirm-title-c .jconfirm-title {
  color: #000 !important;
  font-weight: 600;
}

.jconfirm .jconfirm-holder {
  max-height: 100%;
  padding: 50px 10px;
}

.jconfirm-content div {
  color: #000 !important;
  font-weight: 500;
}



.homepage_button {
  width: 100%;
  margin: 10px 0 0 0;
  text-align: center;
  cursor: pointer;
  /* color: #fff; */
  /* background-color: #ff6251; */
  padding: 12px 30px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-appearance: none;
  background-color: #37B6FF;
  color: #000;
}
.contactform {
  padding: 10px 0 !important;
}
.radio{
  display: flex;
  align-items: center;
}
.radio label{
  font-weight: 500;
  margin-left: 5px;
}

.form_row{
  position: relative;
}
.form_row i{
  /* color: #37B6FF ; */
  font-size: 30px;

}
.form_row i.fa-plus-square{
  position: absolute;
  top: 35px;
  right: 35px;
}
.form_row i.fa-minus-square{
  position: absolute;
  font-size: 30px;
  top: 35px;
  right: 5px;
}

.old-client .form_row select{
  width: 100%;
  padding: 5px 10px;
  height: 35px;
  padding: 10px 3%;
  width: 94%;
  margin: 0 0 15px 0;
  border: 1px solid #dfdfdf;
  background-color: #fbfbfb;
  color: black;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0!important;
  background-image: -webkit-linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0));
}
.text-green {
  color: #6cff3c !important;
}

.img-right {
  right: 15px;
  position: absolute;
  width: 31px !important;
  padding-top: 6px;
}

ul.features_list_detailed li .feat_small_details h4,
.feat_small_details {
  color: #fff;
}

ul.features_list_detailed li .feat_small_icon {
  padding-top: 5px;
}

.success {
  padding: 10px;
  background-color: #6cff3c;
  color: #000;
  font-size: 14px;
  font-weight: 700;
}

.jconfirm .jconfirm-box div.jconfirm-title-c .jconfirm-title {
  color: #000 !important;
  font-weight: 600;
}

.jconfirm .jconfirm-holder {
  max-height: 100%;
  padding: 50px 10px;
}

.jconfirm-content div {
  color: #000 !important;
  font-weight: 500;
}

.homepage_button {
  width: 100%;
  margin: 10px 0 0 0;
  text-align: center;
  cursor: pointer;
  /* color: #fff; */
  /* background-color: #ff6251; */
  padding: 12px 30px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-appearance: none;
  background-color: #37B6FF;
  color: #000;
}
.radio{
  display: flex;
  align-items: center;
}
.radio label{
  font-weight: 500;
  margin-left: 5px;
}
.old-client{}
.old-client .form_row{}
.old-client .form_row select{
  width: 100%;
  padding: 5px 10px;
  height: 35px;
  padding: 10px 3%;
  width: 94%;
  margin: 0 0 15px 0;
  border: 1px solid #dfdfdf;
  background-color: #fbfbfb;
  color: black;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0!important;
  background-image: -webkit-linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0));
}
.btn-primary{
   width: 100%;
   background-color: #37B6FF;
color: #000;
font-size: 14px;


}
a{text-decoration: none;}
.btn-primary:hover{
  background-color: #37B6FF;
}

.input1{
  padding: 10px 3%;
width: 94%;
margin: 0 0 15px 0;
border: 1px solid #dfdfdf;
background-color: #fbfbfb;
color: black;
-webkit-appearance: none;
-webkit-border-radius: 0;
border-radius: 0!important;
background-image: -webkit-linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0));
}


.form1{



padding: 20px;
}textarea{
padding: 10px 3%;
width: 94%;
margin: 0 0 15px 0;
border: 1px solid #dfdfdf;
background-color: #fbfbfb;
color: black;
-webkit-appearance: none;
-webkit-border-radius: 0;
border-radius: 0!important;
background-image: -webkit-linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0));

}
/* th{
background-color: #ff4800;
color: white;
padding: 10px;
} */
td{
padding: 10px;
}
.container-fluid1{
padding: 5px;
}
a{
text-decoration: none;
}
td:hover{
background-color: #ccc;
color:white;
cursor: pointer;
}
p.small{
  font-size: 12px;
}

.main123{
  padding: 20px;
}
.form-group {
  margin-bottom: 10px;
}
.form-group.color {
  display: flex;
  align-items: center;
}
.form-group.color label{
  margin-right: 5px;
}
h3{
  margin: 0 !important;
}

#dvResellerName{
  margin: 0;
  padding: 5px 18px;
  background-color: #ccc;
  color: #000;
  font-size: 15px;
  font-weight: 600 ;
  margin-bottom: 20px;
  text-align: center;
}
.back-ico{
    position: absolute;
    left: 10px;
}

.wrapper1 div {
  display: flex;
}
 .wrapper1 div input{
   margin-right: 5px;
 }
 /* .page_single{
   display: none;
 } */
 .wrapper1 div p{
  margin-right: 10px;
}

#dvResellerName1{
  margin: 0;
   padding: 5px 18px;
    background-color: #ccc;
     color: #000;
      font-size: 15px;
       font-weight: 600;
        margin-bottom: 20px;


}
.from-group1 label{
  margin-right: 100px;
}
.text-green {
  color: #6cff3c !important;
}

.img-right {
  right: 15px;
  position: absolute;
  width: 31px !important;
  padding-top: 6px;
}

ul.features_list_detailed li .feat_small_details h4,
.feat_small_details {
  color: #fff;
}

ul.features_list_detailed li .feat_small_icon {
  padding-top: 5px;
}

.success {
  padding: 10px;
  background-color: #6cff3c;
  color: #000;
  font-size: 14px;
  font-weight: 700;
}

.jconfirm .jconfirm-box div.jconfirm-title-c .jconfirm-title {
  color: #000 !important;
  font-weight: 600;
}

.jconfirm .jconfirm-holder {
  max-height: 100%;
  padding: 50px 10px;
}

.jconfirm-content div {
  color: #000 !important;
  font-weight: 500;
}

.homepage_button {
  width: 100%;
  margin: 10px 0 0 0;
  text-align: center;
  cursor: pointer;
  /* color: #fff; */
  /* background-color: #ff6251; */
  padding: 12px 30px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-appearance: none;
  background-color: #37B6FF;
  color: #000;
}
.radio{
  display: flex;
  align-items: center;
}
.radio label{
  font-weight: 500;
  margin-left: 5px;
}
.old-client{}
.old-client .form_row{}
.old-client .form_row select{
  width: 100%;
  padding: 5px 10px;
  height: 35px;
  padding: 10px 3%;
  width: 94%;
  margin: 0 0 15px 0;
  border: 1px solid #dfdfdf;
  background-color: #fbfbfb;
  color: black;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0!important;
  background-image: -webkit-linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0));
}
.btn-primary{
   width: 40%;
   background-color: #37B6FF;
color: #000;
font-size: 14px;


}
.btn-primary:hover{
  background-color: #37B6FF;
}

.input1{
  padding: 10px 3%;
width: 94%;
margin: 0 0 15px 0;
border: 1px solid #dfdfdf;
background-color: #fbfbfb;
color: black;
-webkit-appearance: none;
-webkit-border-radius: 0;
border-radius: 0!important;
background-image: -webkit-linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0));
}


.form1{



padding: 20px;
}textarea{
padding: 10px 3%;
width: 94%;
margin: 0 0 15px 0;
border: 1px solid #dfdfdf;
background-color: #fbfbfb;
color: black;
-webkit-appearance: none;
-webkit-border-radius: 0;
border-radius: 0!important;
background-image: -webkit-linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0));

}
/* th{
background-color: #ff4800;
color: white;
padding: 10px;
} */
td{
padding: 10px;
}
.container-fluid1{
padding: 5px;
background-color: white;
}
td:hover{
background-color: #ccc;
color:white;
cursor: pointer;
}

.form-group1 .label{

margin-right: 200px;
}
.form-group1{
  margin-top: 20PX;
}

/* th{
  border: 1px solid;
}
tr{
  border: 1px solid;
}
td{
  border: 1px solid;
}  */
.container-fluid1{
  padding: 10px;
}
.text-green {
  color: #6cff3c !important;
}

.img-right {
  right: 15px;
  position: absolute;
  width: 31px !important;
  padding-top: 6px;
}

ul.features_list_detailed li .feat_small_details h4,
.feat_small_details {
  color: #fff;
}

ul.features_list_detailed li .feat_small_icon {
  padding-top: 5px;
}

.success {
  padding: 10px;
  background-color: #6cff3c;
  color: #000;
  font-size: 14px;
  font-weight: 700;
}
.log{
    display: flex;
    background-color:  black !important;
    align-items: center;
    justify-content: space-between;
}
.log i{
  color: white;
  font-size: 20px;
  margin: 10px;
}
.danger {
  padding: 10px;
  background-color: #fd1d1d;
  color: white;
  font-size: 14px;
  font-weight: 700;
}
.jconfirm .jconfirm-box div.jconfirm-title-c .jconfirm-title {
  color: #000 !important;
  font-weight: 600;
}

.jconfirm .jconfirm-holder {
  max-height: 100%;
  padding: 50px 10px;
}

.jconfirm-content div {
  color: #000 !important;
  font-weight: 500;
}

.homepage_button {
  width: 100%;
  margin: 10px 0 0 0;
  text-align: center;
  cursor: pointer;
  /* color: #fff; */
  /* background-color: #ff6251; */
  padding: 12px 30px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-appearance: none;
  background-color: #37B6FF;
  color: #000;
}
.radio-options{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.radio {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.radio label {
  font-weight: 500;
  margin-left: 5px;
  margin-top: 0;
}

.old-client {
}
.client-details{
  margin: 5px 0;
  margin-top: 15px;
}
.client-details>div{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.client-details>div>label{
  width: 70px;
  padding: 0;
  font-weight: bold;
}
.client-details>div>p{
  font-weight: 400 !important;
  padding-bottom: 0 !important;
  color: gray;
  width: 95%;

}
.form_row>div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.form_row>div>label{
  width: 70px;
  padding: 0;
}
.form_row select {
  width: 100%;
  padding: 5px 10px;
  height: 35px;
  /* width: 94%; */
  margin: 0 0 15px 0;
  border: 1px solid #dfdfdf;
  background-color: #fbfbfb;
  color: black;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0 !important;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}
.form_row textarea {
  width: 100%;
  padding: 5px 10px;
  height: 35px;
  padding: 10px 3%;
  /* width: 94%; */
  height: 60px;
  margin: 0 0 15px 0;
  border: 1px solid #dfdfdf;
  background-color: #fbfbfb;
  color: black;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0 !important;
  resize: none;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}
.list{
  margin: 5px 0;
}


.list>li i{
  font-size: 30px;
  margin: 3px;
}
.list>li .details{
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.list>li .details>div{
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
}
.list>li .details .first{
  border-right: 2px solid #80808073;
  padding-right: 5px;
  margin-right: 5px;
  width: 70%;
  min-width: 100px;
}

.list>li{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #80808073;
  margin-top: 10px;

}

.list>li i{
  font-size: 30px;
  margin: 3px;
}
.list>li .details{
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 90%;

}
.list>li .details>div{
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: space-evenly; */
}
.list>li .details .first{
  border-right: 2px solid #80808073;
  padding-right: 5px;
  margin-right: 5px;
  width: 70%;
  min-width: 100px;
}

/* Alert */
.loading{
    display: block !important;
}
.alert-success{
    color: #20a020;
    background-color: #20a0202a;

}
.error{
    color: #a02020;
}
.alert{
    padding: 1rem;
    border: 0.1rem solid transparent;
    border-radius: 0.5rem;
    transition: 0.7s;
}
.alert-info{
    color: #2020a0;
    background-color: #e0e0ff;
}
.alert-danger{
    color: #a02020;
    background-color: #ffe0e0e0;
}

.generate-inv-btn{
      width: 100%;
    margin: 10px 0 0 0;
    text-align: center;
    cursor: pointer;
    color: #fff;
    background-color: #ff6251;
    padding: 12px 30px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    box-shadow: none;
    -webkit-box-shadow: none;
    -webkit-appearance: none;
    background-color: #37B6FF !important;
    color: #000;
}
.navbar{
  background-color:#cce4fb !important;
  text-align: center;
}
.navbar a {
    color: black;
    font-weight: normal;
    font-family: 'Lilita One', cursive;
    text-transform: uppercase;
    font-size: 26px;
    margin:  0 auto !important;

}
.layout_fullwidth_padding {
    min-width: 270px;
    width: 50% !important; 
    margin: 0 auto !important;
}
section>.paywithstripe{
    background-color: lightgray;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px;
    flex-direction: column;
    font-size: 90%;
}
section>.paywithstripe>div{
  display: flex;
}
section>.paywithstripe span{
  margin: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
section>.paywithstripe span label{
  margin-left: 5px;
}
section>.StripeCheckout{
    border: none !important;
    border-radius: 30px !important;
    color:#008B8B;
    padding: 5px 20px !important;
    font-size: 15px;
    margin: 15px auto !important;
    background: none !important;
      background-color: #008B8B !important;

}
section>.StripeCheckout::after{
  content: "PAY NOW";
    border: none !important;
    border-radius: 30px !important;
    color: white;
    font-size: 15px;
    width: 100%;
    font-weight: bold;
}
section>.StripeCheckout span{
  display: none !important;background-color: #008B8B;
  background-image: none;
  color: #008B8B !important;
}
section> .stripe-payment{
  margin: 10px 0;
}
section> .stripe-payment iframe{
  border: 1px solid gray;
}

.list1 li{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: justify;
  border-bottom: 1px solid gray !important;
  margin-bottom: 5px;
  padding: 5px;
}
.list1>.details1{
 width: 70%;
 overflow-wrap: break-word;
}
.list1>.details1 .space{
  margin-top: 5px;  
 }
 ul.list1{
  margin: 15px 0;

}
.list1 .actions{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.list1 .actions i{
  font-size: 30px;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 160px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.modal textarea{
  outline: none !important;
  border: none !important;
}
.modal.show {
  display: block; /* Hidden by default */
}
/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 50%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 10px;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

/* The Close Button */
.close {
    color: black;
    font-weight: bold;
    float: right;
    font-size: 20px;
    background-color: #37B6FF;
    border-radius: 50%;
    padding: 1px 10px;
    position: absolute;
    right: -7px;
    top: -11px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header{
    padding: 2px 16px;
    color: black;
    border-bottom: 1px solid gray;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;

}
.modal-header h4{
    padding: 0 !important;
    color: black !important;
}


.modal-body {padding: 15px;}
.modal-body .bitly{
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: pre;
}
/* .modal-body .bitly button{
    padding: 5px 15px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    color: #000;
} */
.modal-body .message{
    margin-top: 10px;
}
.modal-body p{
    font-size: 14px;
}
.modal-body .message textarea{
    margin: 5px 0;
    padding: 5px;
    outline: none;
    height: 100px;
    border: 1px solid gray;
    border-radius: 10px;
}
.modal-footer{
    border-top: 1px solid gray;
    padding: 2px 16px;
    color: black;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
}
.modal-footer h3{
    padding: 0 !important;
}
.modal-footer button{
    padding: 5px 15px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    color: #000;
    border-radius: 20px;
}
#paymentModal{
  padding-top: 30px !important;
}
.modal-body>div>span{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 5px 5px;
    flex-direction: column;
}
.modal-body>div>span>input{
  margin-right: 5px;
}
.modal-body thead tr th{
    padding: 5px 10px;
    vertical-align: middle;
}
.modal-body #tblSearch {
  margin: 15px auto;
}
.modal-body .amount-details{

}
.modal-body .amount-details>div{
  display: flex;
    align-items: center;
}
.modal-body .amount-details>div p{
  width: 120px;

}
.modal-body .rec-payment-input{
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}
.modal-body span select{
      border: 1px solid gray;
    padding: 5px 10px;
    margin: 5px 0;
    color: black;
    display: block;
    width: 220px;
}
button{
    background-color: #37B6FF !important;
    border: none !important;
    color: black;
    font-size: 15px;
    margin: 5px 0;
    margin-right: 10px;
    text-align: center;
    cursor: pointer;
    padding: 12px 15px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    box-shadow: none;
    -webkit-box-shadow: none;
    -webkit-appearance: none;
}
button:disabled,
button[disabled]{
  background-color: #cccccc !important;
  color: #666666;
}
.modal-body .rec-payment-input input{
  border: 1px solid gray;
  padding: 5px 10px;
  margin: 5px 0;
  color: black;
    width: 220px;

}
.rec-payment-input input::-webkit-outer-spin-button,
.rec-payment-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .layout_fullwidth_padding {
      width: 100% !important;
      padding: 5px 15px;
  }
    .modal-content{
    width: 95%;
    background-color: #ebebeb !important;
    text-align: left;
    border-radius: 10px;
    -webkit-box-shadow: -1px 9px 37px -8px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 9px 37px -8px rgba(0,0,0,0.75);
box-shadow: -1px 9px 37px -8px rgba(0,0,0,0.75);
    }
    .modal-body {padding: 15px;}
    .modal-body .bitly{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

    }
    .modal-body .bitly button{

    }
    .modal-body .message{

    }
  .modal-body .amount-details>div p{
    width: 140px;

  }
  /* .layout_fullwidth_padding {
    max-width: 100% !important;
    margin: 0 auto !important;
} */
}

.tooltip {
  position: relative;
  display: flex;
  width: 70%;
  justify-content: space-around;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  font-weight: 100;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltip button{
    margin: 5px;

}
    
h6.match{
  color: green;
}
h6.not-match{
  color: red;
}
.contactform input.form_input{
  width: 100%;
}
.contactform input.form_input.match {
  border: 1px solid green;
}
.contactform input.form_input.not-match {
  border: 1px solid red;
}
.signout-btn{
    width: 40%;
    margin: 10px 0 0 0;
    text-align: center;
    cursor: pointer;
    color: black;
    background-color: #37B6FF;
    padding: 12px 30px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    box-shadow: none;
    -webkit-box-shadow: none;
    -webkit-appearance: none;
}
.loader{
  margin: 0 10px;
  margin-top: 10px;
}


.generate-icon{
  position: relative;
}
.generate-icon i{
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 26px;
}

#saver{
  margin-top: 10px;
}
#date{
    border: 1px solid lightgray;
    width: 100%;
    padding: 5px 10px;
    margin: 0 0 15px 0;

}
input[type=date] {
    color: black;
}








.dropbtn {

  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  float: right;
  height: 100%;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1;}

/* .dropdown:hover .dropdown-content {
  display: block;
} */

/* .dropdown:hover .dropbtn {
  background-color: #3e8e41;
} */


.total-customer-data{
  margin: 40px 0;
}
.total-customer-data>div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.total-customer-data>div>div{
  margin-right: 20px;
  min-width: 30%;
}














.folder-items {
    padding-left: 20px;
}

.folder > .btn-group {
    margin-bottom: 20px;
}

.folder > .btn-group > .file-system-folder {
    margin-right: 10px;
    border: 0;
}

.file-system-history {
    border-radius: 0;
    margin: 5px 0;
}

.file-system-item {
    padding: 5px;
    height: 42px;
}

.file-system-item > .row {
    display: flex;
    align-items: center;
    height: 100%;
}

/* .file-system-item .item-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
} */

.file-system-file {
    border-left: 0;
    border-right: 0;
}

.file-system-folder:focus {
    outline: none;
}

.file-system-folder {
    border-left: 0;
    border-right: 0;
}

.file-system-file-header {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom-width: 3px;
    font-weight: bold;
}

.changing { 
    transition: all .2s ease-in-out;
    transform: scale(10);
}

.edit-item-form-group {
    margin-bottom: 0;
}

.edit-item-input {
    border-radius: 0;
    height: 100%;
}

.item-icon-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.item-icon-container > .glyphicon {
    top: 0px;
}

.item-icon-container > .preview-img {
    max-width: 100%;
    max-height: 20px;
}

.item-icon-container > .item-icon {
    background-color: #ddd;
    color: #777;
    padding: 4px 3px;
    border-radius: 6px;
    width: 65px;
    text-align: center;
}

.icon-title{
  word-break: break-word;
}

.file-add-btn{
    border: none;
    cursor: pointer;
    font-size: 40px !important;
    /* color: #4AB7FF !important; */
    background: transparent !important;
    position: absolute;
    bottom: 0;
    right: -12px;
}

.file-system-popup{
  
}

.dropdown-content.file-system{
    right: 46px;
    bottom: 26px;
    background: rgba(233, 232, 232, 0.959);
    width: 145px;
    z-index: 1000;
    border: 1px solid rgba(221, 224, 228, 0.5);
    box-shadow: 0 12px 24px 0 rgb(0 0 0 / 5%);
    border-radius: 8px;
    border: 0.5px solid gray;
}
.dropdown-content.file-system a{
  border-bottom: 0.5px solid gray;
}
.file-sytem-main{
  position: relative;
}
.file-sytem-main>.dropdown-content.uploader{
  position: absolute;
  top: 95%;
  left: 5%;
  /* margin-right: -50%; */
  /* transform: translate(-51%, -67%); */
  min-width: 320px;
  min-height: 200px;
  background-color: lightgray;
}
.dropdown-content.uploader{
    padding: 10px;
    display: flex !important;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}
.dropdown-content.uploader .head{
  /* padding: 5px 10px; */
}
.dropdown-content.uploader .foot{
  /* padding: 5px 10px; */
}
.dropdown-content.uploader .foot button{
  /* padding: 5px 10px; */
}
.dropdown-content.uploader .input{
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.dropdown-content.uploader .input label{
  padding: 5px 0;
}
.dropdown-content.uploader .input input{
  padding: 5px 10px;
  outline: none;
  border: 1px solid gray;
  color: black;
}

.data-box{

}


.files-view{
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    height: 90vh;

}
.files-view>div{
  width: 100%;
}
.files-view .head{}
.files-view .head .navigation{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  font-size: 18px;
}
.files-view .head .navigation .icon{
  margin-right: 20px;
  font-size: 25px;
}
.files-view .head .navigation .path{}
.files-view .head .search{}
.files-view .head .search input:focus{
  outline:none;
  border: none;
}

.files-view .body{
  max-height: 100%;
  /* background-color: gray; */
  overflow: scroll;
}
.files-view .body .list{
  margin-top: 20px;
}
.files-view .body .list li{
  border-bottom: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.files-view .body .list li .image{
  margin-right: 20px;
  width: 35px;
}

.docusign-form-div{
  display: flex;
  flex-direction: column;
  margin: 5px 20px;
}

.sign-box{
  border: 1px solid gray;
  padding: 5px;
  margin: 0 5px;
  margin-bottom: 20px;
  height: 150px;
}

.css-2b097c-container{
  margin: 10px 0 !important;
}
.css-yk16xz-control{
  width: 100% !important;
}
.css-1pahdxg-control{
  width: 100% !important;
}