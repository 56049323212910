.container-fluid {
    padding: 0px 50px;

}
.container-fluid .dvMainInvoice{
    background-color: #fff;
    margin: auto;
    padding: 100px;
}
.dvMainInvoice #tblSearch{
    min-width: 100%;
} 
.dvMainInvoice table,
.dvMainInvoice th,
.dvMainInvoice tr,
.dvMainInvoice td{
    border: 0px;
}
.dvMainInvoice #tblSearch th{
    background: #37B6FF !important;
}
.dvMainInvoice #tblSearch th>div{
    padding-right: 5px 20px !important;
    min-height: 30px !important;
    padding: 15px !important;

}
.dvMainInvoice #tblSearch tr{
}
.dvMainInvoice #tblSearch tr:hover,
.dvMainInvoice #tblSearch td:hover{
    background-color: lightgray !important;
    color: black !important;
    cursor: pointer;
}
 section div 
    {float: 'left';
         padding-right: '20px';
         display: 'inline-block'; 
         font-size: '19px';
          padding: '7px';
        }
        #memo{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            
        }
        #memo>div{
            max-width: 40%;


        }
        #memo div span{
            color: '#0b4978';
                 display: 'inline-block'; min-width: '20px';
                
        
        }
        
        
        .inv-details{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        .inv-details>section{

        }
        .container-fluid  .second .dvMainInvoice .section{



        }
    .container-fluid  .second .dvMainInvoice .invoice-section .first{

        display: block;
        color: #FB5859;
        font-size: 26px;
        font-weight: bold;  
    
    }
    .container-fluid  .second .dvMainInvoice .section .second span{
        text-align: left;
        font-size: 14px;

    }
    .container-fluid  .second .dvMainInvoice .section1 div{
        text-align: right;
        font-weight: 18px;
    }
    .container-fluid  .second .dvMainInvoice .invoice-section div .first{
      color: #FB5859;
      font-size: 20px !important;
    }

.container-fluid  .second .dvMainInvoice .below span{                  
    font-size: 12px !important;
    margin: 0 !important;
}
        .container-fluid  .second .dvMainInvoice .below>div{
            width: 100%;
        } 
        .container-fluid  .second .dvMainInvoice .items table thead .first{
width: 75%;

        }
        .container-fluid  .second .dvMainInvoice .items table thead .first div{
text-align: left;            
                    }
        .container-fluid  .second .dvMainInvoice .items table thead .second{
            width: 75%;
            
                    }
                    .container-fluid  .second .dvMainInvoice .items table thead .second div{
                        text-align: right;            
                                            }

    .container-fluid  .second .dvMainInvoice .items table tbody tr .first{
text-align: left;
text-align: justify;
    }
    .container-fluid  .second .dvMainInvoice .items table tbody tr .second{
        text-align: right;
            }
            .container-fluid  .second .dvMainInvoice .below div
            {
                
                float: right;
                display: inline;
                text-align: right;
                color:#858585 ;
                font-weight: bold;
                font-size: 12px;
                font-style: italic;
                margin-top: 10px;
                
                  }    

            .container-fluid  .second .dvMainInvoice .sums{
                margin-top: 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
            }
            .payment-info {
                    font-size: 14px;
                    color: #858585;
                    font-style: italic;
                    line-height: 1.5em;
                    width: 100%;

                          }
            .container-fluid  .second .dvMainInvoice .sums  .row .col  #dvFooter .payment-info .second span
            {
             columns: black ;
             font-weight: bold;
            }
.container-fluid  .second .dvMainInvoice .sums .first1{
    margin-left: auto;   

}

.container-fluid  .second .dvMainInvoice .sums .first1 tbody .amount-total .first{
    width: 20%;
    text-align: right;

}
.container-fluid  .second .dvMainInvoice .sums .first1 tbody .amount-total .second{
    text-align: left;
    font-size: 27px;

}
.container-fluid  .second .dvMainInvoice .sums .first1 tbody .amount-total .third{
    text-align: left;
    font-size: 18px;

}
.container-fluid  .second .dvMainInvoice .sums .first1 tbody .amount-total .td{
    width: 80%;
    text-align: right;
    font-size: 28px;

}
.sums table tr.amount-total td {
    background-color: #37B6FF;
    color: black;
    /* font-family: Sanchez, Serif; */
    font-size: 27px;
    line-height: 1em;
    padding: 7px ;
    font-weight: 500;
}
#memo div img{
    width: 300px;
}
#memo  .second{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
#memo  .second span {
    font-size: 14px;
    margin-top: 5px;
}
.sums table tr th, .sums table tr td {
    min-width: 100px;
    padding: 10px 3px;
    text-align: right;
}



  




        
@media only screen and (max-width:600px){
    .container-fluid{
        padding: 0!important;
    }
    .container-fluid  .second .dvMainInvoice{
        padding: 20px 10px;
        min-width: 100% !important;
        margin: 0 !important;
        text-align: left !important;
    } 
    #memo{
        flex-direction: column;
    }
    #memo>div{
        min-width: 100%;
    }
    .inv-details{
        flex-direction: column;
    }
    .dvMainInvoice #memo .second {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .dvMainInvoice .sums .row>div{
        width: 100% !important;
    }
    .container-fluid .second .dvMainInvoice .items table tbody tr .first {
     width: 100%;
    }
    
    #memo div img{
        width: 100%;
    }
    .container-fluid  .second .dvMainInvoice .section1 div .first{
        color: #FB5859;
        font-size: 24px};
    .container-fluid  .second .dvMainInvoice .section1 div{
        font-weight: 18px;
    }
    .container-fluid  .second .dvMainInvoice .sums{
        flex-direction: column;
    }
    .container-fluid .second .dvMainInvoice .sums .first1 {
        width: 100% !important;
        margin-bottom: 20px;
    }
    .container-fluid  .second .dvMainInvoice .section1 div{
        text-align: center;
        font-weight: 18px;
    }
    .container-fluid  .second .dvMainInvoice .sums.first1 tbody .amount-total .second{
        width: 100%;
        text-align: right;
        font-size: 20px;
    
    }
    .container-fluid  .second .dvMainInvoice .below>div
        {
            
            float: right;
            display: inline;
            text-align: center;
            color:#858585 ;
            font-weight: bold;
            font-size: 12px;
            font-style: italic;
            margin-top: 10px;
            width: 100%;
              }   
    .container-fluid  .second .dvMainInvoice .section1{
        margin: 0px;
        padding-left: 0px;
    }




.container-fluid .second .dvMainInvoice .items table .desc .first {
    display: flex;
    flex-wrap: wrap;
    width: 100%!important;
}
    .sums table tr th, .sums table tr td {
        min-width: 0px;
        padding: 10px 0px;
        text-align: right;
    }
    

    


    .container-fluid  .second .dvMainInvoice .sums  .row .col  .first1  .mob{
        
    }
















    /* .container-fluid .second .dvMainInvoice .sums .row .col .first1 tbody .amount-total .second {
        width: 100%;
        text-align: right;
        font-size: 13px;
    } */


    
    .container-fluid  .second .dvMainInvoice .items table .desc{
    }
    .container-fluid  .second .dvMainInvoice .items table .desc td{
text-align: justify;    }


.container-fluid  .second .dvMainInvoice .sums  .row .col  .first1 tbody .amount-total
th{
   width:0% !important;
}


    .container-fluid  .second .dvMainInvoice .section .first{

        display: block;
        color: #FB5859;
        font-size: 34px;
        font-weight: bold;  
        margin-top: -10px; 
            
            }
    .container-fluid  .second .dvMainInvoice .section{
        float: left;
        text-align: left;
        margin-top: 20px;
        width: 100%;
    }
}

.sums .row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}
.sums .row>div{}

.admin-use{

}
.admin-use h4{
    color: red;
}
.admin-use .send{
}

.invoice-section{
    padding-top: 20px!important;
    margin: 0!important;
}
.download-btn{
    text-transform: uppercase;
    color: red;
    font-weight: bold;
}
.gst-box{
    display: flex;
}
.gst-span{
    margin: 2px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}
.gst-span label{
    margin-left: 5px;
}

@media only screen and (max-width: 600px) {
  .pad{
      padding: 0px !important;
  } 
.width{
    width:100% !important;
}
.width div{
    width: 70%;
}
.width:nth-child(even){
    max-width: 60%;
}
.td{
    padding: 6px!important;
}
.imagelogo{
    width: 100%!important;
    height: 100%!important;
}
.MT{
    margin-top: 20px!important;
}




  }